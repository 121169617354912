
import { Component, Vue } from 'vue-property-decorator';
import { getReservationClient } from '@/api/reservationClient';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import { addReservationReview } from '@/api/reservationReview';
import ReservationClient from './components/reservationClient.vue';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getReservationClient();
  }

  private apiUrl = process.env.VUE_APP_BASE_API;

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private fileSizeLimit = 5;

  private goBack() {
    this.$router.go(-1);
  }

  private reservationClient = {
    uid: '',
    name: '',
    phone: '',
    useDate: '',
    endDate: '',
    checkIn: '',
    checkOut: '',
    reservationCode: '',
    paymentDate: '',
    paymentType: '',
  }

  private reviewForm: any = {
    content: '',
    score: 0,
    images: [],
  }

  private rules = {
    content: [
      { required: true, message: '리뷰내용을 입력해주세요.', trigger: 'blur' },
    ],
    score: [
      {
        type: 'number',
        min: 1,
        message: '별점을 남겨주세요.',
        trigger: 'blur',
      },
    ],
  }

  private getReservationClient() {
    this.loading = true;
    getReservationClient(this.$route.params.uid).then((res) => {
      this.reservationClient = res.data;
      this.loading = false;
    });
  }

  private handleAddReview() {
    (this.$refs.reviewForm as ElForm).validate((valid) => {
      if (valid) {
        addReservationReview(this.$route.params.uid, this.reviewForm).then(() => {
          this.$message.success('리뷰 등록을 완료했습니다.');
          this.$router.push({ name: 'mypageOrder' });
        }).catch(() => {
          this.$message.error('리뷰 등록을 실패했습니다.');
        });
      }
    });
  }

  private handleBeforeUpload(uploadFile:File, isPhoto:boolean) {
    const fileSizeLimitByMb = this.fileSizeLimit * 1024 * 1024;
    if (isPhoto) {
      const isImageFile = uploadFile.type.split('/')[0] === 'image';
      if (!isImageFile) {
        this.$message.warning('이미지 파일만 업로드 가능합니다.');
        return false;
      }
    }
    if (uploadFile.size > fileSizeLimitByMb) {
      this.$message.warning(`파일 업로드 최대용량은 ${this.fileSizeLimit}MB입니다.`);
      return false;
    }
    return true;
  }

  private handleRemoveImageFile(file: any) {
    const index = this.reviewForm.images.findIndex((imgFile: any) => imgFile.fileUid === file.fileUid);
    if (index > -1) this.reviewForm.images.splice(index, 1);
  }

  private handleSuccessUploadImageFile(res: any) {
    this.reviewForm.images.push({ fileUid: res.uid });
  }

  private handleExceed() {
    this.$message.error('이미지는 1장만 업로드 가능합니다.');
  }
}
